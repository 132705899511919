const ai = {
  ai: {
    dialog: {
      title: {
        create: {
          customSurvey: "Create custom survey with AI assistant",
        },
      },
      description: {
        create: {
          customSurvey:
            "Describe the survey you want to create, or upload a file with your questions (.txt or .xlsx), and our AI assistant will help you design it.",
        },
      },
      placeholder: "Type your prompt here...",
      fileUpload: {
        title: "Upload file",
        clickToUpload: "Click to upload a file",
        orDragAndDrop: "or drag and drop a file here",
        supportedFormats: "Allowed files: .txt, .xlsx",
        invalidFileType: "Please upload only .txt or .xlsx files",
        fileTooLarge: "The file is too large. The maximum size is 10MB.",
      },
    },
  },
};

export default ai;
