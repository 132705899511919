const ai = {
  ai: {
    dialog: {
      title: {
        create: {
          customSurvey: "Criar pesquisa personalizada com assistente de IA",
        },
      },
      description: {
        create: {
          customSurvey:
            "Descreva a pesquisa que você deseja criar ou faça upload de um arquivo com suas perguntas (.txt ou .xlsx), e nosso assistente de IA ajudará você a projetá-la.",
        },
      },
      placeholder: "Digite seu comando aqui...",
      fileUpload: {
        title: "Enviar arquivo",
        clickToUpload: "Clique para enviar um arquivo",
        orDragAndDrop: "ou arraste e solte um arquivo aqui",
        supportedFormats: "Arquivos permitidos: .txt, .xlsx",
        invalidFileType: "Por favor, envie apenas arquivos .txt ou .xlsx",
        fileTooLarge: "O arquivo é muito grande. O tamanho máximo é 10MB.",
      },
    },
  },
};

export default ai;
