const TimeOff = {
  timeOff: {
    title: "Time Off",
    user: "User",
    team: "Team",
    accumulated: "Accumulated",
    requested: "Requested",
    pending: "Pending",
    all: "All",
    days: "Days",
    name: "Name",
    category: "Category",
    status: "Status",
    approved: "Approved",
    rejected: "Rejected",
    history: "History of requests",
    freeDays: "Free days",
    noFreeDays: "No free time off policies",
    noHistory: "No history of requests",
    detailTitle: "Detail of free days usage",
    headersDays: {
      category: "Category",
      politic: "Policy",
      solicited: "Requested",
      accumulated: "Accumulated",
    },
    headersHistory: {
      date: "Date",
      politic: "Policy",
      solicited: "Requested",
      approved: "Approved",
      state: "State",
    },
    filter: {
      team: "Filter by team",
      policy: "Filter by policy",
    },
    tabs: {
      all: "All",
      admin: "Administrative",
      legal: "Legal",
      additional: "Additional",
    },
    months: {
      0: "January",
      1: "February",
      2: "March",
      3: "April",
      4: "May",
      5: "June",
      6: "July",
      7: "August",
      8: "September",
      9: "October",
      10: "November",
      11: "December",
    },
  },
};

export default TimeOff;
