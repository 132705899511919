import { SET_WORKFLOWS_IDS } from "../actions/types";

const initialState = {
  n8n: [], // Array to store n8n workflow IDs
  // Add other workflows here if needed
};

export default function aiWorkflowReducers(state = initialState, action) {
  switch (action.type) {
    case SET_WORKFLOWS_IDS:
      return {
        ...state,
        n8n: action.payload,
      };
    default:
      return state;
  }
}
