const surveys = {
  surveys: {
    title: "Suas pesquisas",
    newSurvey: "Nova pesquisa",
    actives: "Ativas",
    scheduled: "Agendadas",
    archived: "Arquivadas",
    drafts: "Rascunhos",
    archiveTitle: "Deseja arquivar esta pesquisa?",
    totalUsersSent: "Enviado para",
    totalUsersResponse: "Resposta",
    coworker: "colaborador",
    coworkers: "colaboradores",
    all: "todos",
    editFields: "Título e descrição",
    sendReminder: "Enviar lembrete para colaboradores que não responderam",
    anonymous: {
      true: "Esta pesquisa é anônima; o administrador não poderá ver suas respostas individuais.",
      false:
        "Esta pesquisa não é anônima; o administrador poderá ver suas respostas individuais.",
    },
    questions: {
      helper: {
        text: "Digite sua resposta aqui",
        multipleChoice: "Selecione uma opção",
        priorityOrder: "Arraste as opções na ordem de prioridade que preferir",
        nps: 'Onde 0 é "Não recomendaria de forma alguma" e 10 é "Recomendaria com certeza"',
      },
      npsTextLow:
        "Quais aspectos devemos melhorar para aumentar sua pontuação?",
      npsTextHigh:
        "O que podemos fazer para garantir que você continue nos recomendando como local de trabalho?",
    },
    answer: {
      alert:
        "Esta é uma prévia da pesquisa que será enviada aos colaboradores, as respostas a seguir não serão armazenadas!",
    },
    team: {
      singular: "Equipe ",
      plural: "Equipes ",
      seeAll: "Ver equipes",
    },
    segment: {
      singular: "Segmento ",
      plural: "Segmentos ",
      seeAll: "Ver segmentos",
    },
    individual: {
      singular: "Funcionário",
      plural: "Funcionários",
      seeAll: "Ver todos os funcionários",
    },
    warningMessage: {
      alertWarningMessage:
        "É necessário ter pelo menos quatro colaboradores em alguma categoria",
    },
    table: {
      title: "Título",
      status: "Status",
      percentageResponse: "% de resposta",
      createdAt: "Data de criação",
      scheduled: "Agendada para a data",
      visibility: "Visibilidade",
    },
    show: {
      surveys: "Pesquisas",
      detailSurvey: "Detalhes da pesquisa",
      responseRate: "Taxa de resposta",
      status: "Status",
      active: "Ativa",
      archived: "Arquivada",
      published: "Publicada",
      scheduled: "Agendada",
      content: "Conteúdo",
      question: "pergunta",
      questions: "perguntas",
      anonymous: "Anônimo",
      noAnonymous: "Não anônimo",
      visibility: "Visibilidade",
    },
    create: {
      buttonMore: {
        seeDraft: "Ver rascunho",
        seeTemplate: "Ver modelo",
        seeSurvey: "Ver pesquisa",
        saveDraft: "Salvar como rascunho",
        deleteDraft: "Excluir rascunho",
        saveTemplate: "Salvar como modelo",
        saveAsNewTemplate: "Salvar como novo modelo",
        updateTemplate: "Atualizar modelo",
        delete: "Excluir pesquisa",
        deleteTemplate: "Excluir modelo",
      },
      firstStep: {
        dialogTitle: "Criar pesquisa personalizada",
        dialogTitleTemplate: "Novo modelo",
        title: "Título",
        titlePlaceholder: "Digite o nome da pesquisa",
        titlePlaceholderTemplate: "Digite o nome do modelo",
        description: "Descrição",
        finalMessage: "Adicionar mensagem ao final da pesquisa",
        descriptionPlaceholder: "Digite a descrição",
        required: "Obrigatório",
        lowUsers:
          "Você precisa de um mínimo de 4 colaboradores para uma pesquisa anônima",
      },
      secondStep: {
        questionSummary: "Pergunta {{number}}",
        questionTitle: "Título da pergunta",
        questionTitlePlaceholder: "Como você descreveria seus colegas?",
        title: "Tipo de pergunta",
        text: "Texto",
        nps: {
          title: "NPS",
          label:
            "As perguntas de <strong>NPS</strong> são configuradas automaticamente em uma <strong>escala de 0 a 10</strong>, onde 0 é <strong>Não recomendaria de forma alguma</strong> e 10 é <strong>Recomendaria com certeza</strong>. Os usuários que respondem com 9 ou 10 serão considerados como <strong>promotores</strong>, 7-8 como <strong>passivos</strong>, e 0-6 como <strong>detratores</strong>.<br />Além disso, tanto passivos quanto detratores devem fornecer comentários para completar sua avaliação.",
        },
        opinionScale: {
          title: "Escala de opinião",
          minLabel: "Rótulo 0",
          minLabelPlaceholder: "Exemplo: discordo totalmente",
          maxLabel: "Rótulo 10",
          maxLabelPlaceholder: "Exemplo: concordo totalmente",
        },
        multipleChoice: {
          title: "Escolha múltipla",
          optionLabel: "Opção {{number}}",
          optionLabelPlaceholder: "Opção {{number}}",
        },
        priorityOrder: {
          title: "Ordem de prioridade",
          optionLabel: "Opção {{number}}",
          optionLabelPlaceholder: "Opção {{number}}",
        },
        newQuestion: "Adicionar outra pergunta",
        newOption: "Adicionar outra opção",
      },
      thirdStep: {
        dialogTitle: "Público e permissões",
        dialogSubtitle: "Usuários inativos NÃO receberão esta pesquisa",
        title: "Quem responderá a esta pesquisa personalizada?",
        creator: "Criador",
        organization: "Organização",
        audience: "Público",
        all: "Todos",
        team: "Equipe",
        segment: "Segmento",
        selectTeam: "Selecionar equipe",
        selectSegment: "Selecionar segmento",
        shippingOptions: "Opções de envio",
        sendNow: "Enviar agora",
        scheduleShipping: "Agendar",
        selectDate: "Data",
        selectTime: "Hora",
        previsualization: "Pré-visualização de envio",
        anonymous: "Pesquisa anônima",
        notAnonymous: "Pesquisa não anônima",
        deliveryOptions: "Opções de envio",
      },
      editFields: "Novo título e descrição",
      newTemplateFields: "Título e descrição do novo modelo",
      success: "Pesquisa criada com sucesso!",
      button: "Ver minhas pesquisas",
      saveSuccess: "Pesquisa enviada com sucesso",
      scheduledSuccess: "Pesquisa agendada com sucesso",
      saveSuccessTemplate: "Sucesso! Modelo salvo",
      saveError:
        "Ocorreu um erro ao criar a pesquisa, por favor, tente novamente",
      saveErrorTemlate:
        "Ocorreu um erro ao criar o modelo, por favor, tente novamente",
      noQuestions:
        "Você precisa adicionar pelo menos uma pergunta para enviar esta pesquisa",
    },
    user: {
      beginTitle: "Começar",
      back: "Anterior",
      next: "Próximo",
      end: "Finalizar",
      complete: "Completado",
      incomplete: "Incompleto",
      deleteColabs: "Tem certeza de que deseja deletar este colaborador?",
      deleteColabsTitle: "Deletar Colaborador",
      addColabsTitle: "Colaborador que deseja adicionar",
      addColabs: "Selecione o colaborador que deseja adicionar",
      thanks: "Muito obrigado por suas respostas!",
      close: "Fechar esta janela",
      textOption: {
        placeholder: "Digite sua opinião aqui",
      },
      labels: "Onde 0 é {{ badLabel }} e 10 é {{ goodLabel }}",
    },
    tooltips: {
      visibility: "Ver resultados",
      sendReminder: "Enviar lembrete",
      archive: "Arquivar pesquisa",
      download: "Baixar relatório",
      cancelSending: "Cancelar envio",
      reschedule: "Reagendar pesquisa",
      information:
        "A equipe ou segmento requer 4 usuários não desativados (pesquisa anônima) ou 1 (não anônima) para ser incluído no público",
      addColabs: "Adicionar colaboradores",
    },
    report: {
      success: "Relatório baixado com sucesso",
      noInfo: "Ainda não há respostas para baixar",
    },
    reminder: {
      successMessage: "Lembrete de pesquisa enviado com sucesso",
      errorMessage:
        "Ocorreu um erro ao enviar o lembrete de pesquisa, por favor, tente novamente",
    },
    addColabs: {
      title: "Escolha colaboradores para adicionar à pesquisa",
      required: "Selecione pelo menos 1",
      successMessage: "Colaboradores adicionados com sucesso",
      successDeleteMessage: "Colaborador deletado com sucesso",
      errorMessage:
        "Houve um erro ao adicionar os colaboradores, por favor tente novamente",
      errorDeleteMessage:
        "Houve um erro ao deletar o colaborador, por favor tente novamente",
    },
    confirmation: {
      title: "A pesquisa será enviada automaticamente",
      subtitle: "Deseja continuar?",
    },
    newSurveysButton: {
      emptyPage: "Pesquisa em branco",
      template: "Pesquisa de modelo",
    },
    templateModal: {
      title: "Deseja salvar esta pesquisa como modelo?",
      subtitle:
        "Esta pesquisa será salva como modelo na seção de modelos para uso em pesquisas futuras",
      buttonSave: "Salvar como modelo",
      notSave: "Não salvar",
    },
    newTemplate: {
      saveTemplate: "Salvar modelo",
      deleteTemplate: "Excluir modelo",
      useTemplate: "Usar modelo",
      edit: "Editar",
      titleDeleteModal: "Tem certeza de que deseja excluir este modelo?",
    },
    seeAllAnswers: "Ver todas as respostas",
    answers: {
      emptyStateTitle: "Ainda não há respostas",
      emptyStateSubtitle: "Quando houver, elas serão exibidas aqui",
      emptyStateScheduleTitle: "Envio da pesquisa agendado para",
    },
    responseRate: {
      first: "Taxa de",
      second: "resposta",
    },
    scheduledSurvey: {
      titleDeleteModal: "Deseja excluir a pesquisa ou salvá-la como rascunho?",
      titleRescheduleSurvey: "Deseja reagendar a pesquisa?",
      errorDeleteMessage:
        "Ocorreu um erro ao excluir a pesquisa agendada, por favor, tente novamente",
      deleteSuccess: "Pesquisa excluída com sucesso",
      saveAsDraft: "Salvar como rascunho",
      deleteScheduledSurvey: "Excluir",
      changeScheduleAtSuccess:
        "Data de envio da pesquisa reagendada com sucesso",
    },
    template: {
      title: "Modelos",
      newSurveysButton: "Criar novo modelo",
      used: "Usado em {{date}}",
      created: "Criado em {{date}}",
      updated: "Atualizado em {{date}}",
      notUsed: "Ainda não usado",
      successMessage: "Sucesso! Modelo salvo",
      noQuestions:
        "Você precisa adicionar pelo menos uma pergunta para salvar como modelo",
      noQuestionsUse:
        "Você precisa adicionar pelo menos uma pergunta para usar este modelo",
      templateExist:
        "Já existe um modelo. Se deseja salvar as alterações, por favor, insira um novo título para o modelo",
      errorMessage:
        "Ocorreu um erro ao criar o modelo, por favor, tente novamente",
      errorDeleteMessage:
        "Ocorreu um erro ao excluir o modelo, por favor, tente novamente",
      emptyStateTitle: "Ainda não há modelos",
      emptyStateSubtitle:
        "Você pode criá-los clicando no botão Criar novo modelo",
      emptyStateButtonCreate: "Ou clicando aqui",
    },
    draftSurvey: {
      title: "Rascunho",
      draftSurveyExist:
        "Rascunho já existe! Se deseja salvar o rascunho, coloque outro título",
      noQuestions:
        "Você precisa adicionar pelo menos uma pergunta para usar este rascunho",
      errorMessage:
        "Ocorreu um erro ao criar o rascunho, por favor, tente novamente",
      successDeleteMessage: "Sucesso! Rascunho excluído",
      errorDeleteMessage:
        "Ocorreu um erro ao excluir o rascunho, por favor, tente novamente",
      successMessage: "Sucesso! Rascunho salvo",
      useDraft: "Usar rascunho",
      titleDeleteModal: "Tem certeza de que deseja excluir este rascunho?",
      saveAsDraft: "A pesquisa foi salva como rascunho",
    },
    bozTemplate: {
      title: "Modelos Boz",
      seeAllTemplates: "Ver todos os modelos Boz",
    },
    multipleChoice: {
      lessThan2Options:
        "Perguntas de escolha múltipla precisam ter pelo menos 2 opções",
    },
    priorityOrder: {
      lessThan4Options:
        "Perguntas de ordem de prioridade precisam ter pelo menos 4 opções",
      buttonSeeMore: "Mostrar mais...",
    },
    opinionScale: {
      lessThan2Values:
        "Perguntas de escala de opinião precisam ter os rótulos completos",
    },
    imagesSurvey: {
      anonymousSurvey: "Pesquisa anônima",
      publicSurvey: "Pesquisa pública",
    },
    descriptionsPrefix: {
      anonymousSurvey: "⚠️ SUAS RESPOSTAS SERÃO ANÔNIMAS ⚠️",
      publicSurvey: "⚠️ SUAS RESPOSTAS SERÃO PÚBLICAS ⚠️",
      description: "Descrição",
      subtitle: "Pesquisa personalizada",
    },
    onboarding: {
      title:
        "Antes de começar a pesquisa, pedimos que você complete estas informações",
      teams: {
        noSelected: "Ainda não sei",
      },
    },
  },
  daily_survey: {
    hello: "Olá",
    title: "Sua pesquisa diária chegou!",
    begin: "Iniciar",
    previous: "Anterior",
    next: "Próximo",
    end: "Finalizar",
    success_title: "Sua pesquisa diária foi salva",
    privacy: "Lembre-se de que suas respostas serão mantidas 100% anônimas",
    close: "Fechar esta janela",
    expired:
      "Esta pesquisa já expirou. Lembre-se de que você tem 2 semanas para responder.",
    completed: "Esta pesquisa já foi respondida.",
  },
  performance: {
    title: "Avaliação de Desempenho",
    new: "Nova Performance",
    performanceSections: {
      title: "Performance BOZ",
      evaluationsTitle: "Avaliações: Competências e Objetivos",
      evaluationsDescription: "Crie avaliações de desempenho",
      objectivesTitle: "Objectivos",
      objectivesDescription: "Defina os objetivos das equipes na {{name}}",
      valuesTitle: "Valores / Princípios",
      valuesDescription: "Define dimensões de avaliação de desempenho",
      scalesTitle: "Escalas de Avaliação",
      scalesDescription:
        "Define escalas de avaliação de desempenho para avaliar seus colaboradores",
      reportsTitle: "Relatórios",
      reportsDescription:
        "Relatórios para visualização e análise de desempenho",
    },
    performanceSurveys: {
      new: "Nova Pesquisa",
      title: "Pesquisas de Desempenho",
      seeAllTemplates: "Ver Todas as Pesquisas",
      createSurvey: "Criar Pesquisa",
      surveyType: "Tipo de Pesquisa: {{ type }}",
      openSurvey: "Abrir Pesquisa",
      deleteSurvey: "Excluir Pesquisa",
      titleCancelScheduledSurvey:
        "Tem certeza de que deseja cancelar a pesquisa agendada?",
      cancelScheduledSurvey: "Cancelar",
      errorCancelMessage:
        "Houve um erro ao cancelar a pesquisa agendada, por favor, tente novamente",
      cancelSuccess: "Pesquisa cancelada com sucesso",
    },
    autoevaluation: "Autoavaliação",
    managerTeam: "Gerente para Equipe",
    teamManager: "Equipe para Gerente",
    surveyTypeText: "Escolha o tipo de pesquisa *",
    tabs: {
      all: "Todos",
      completed: "Completadas",
      pendings: "Pendentes",
      updateAt: "Data de Atualização",
    },
    name: "Nome",
    evaluator: "Avaliador",
    status: "Status",
    percentageResponse: "% de Resposta",
    totalSessions: "Total de Sessões",
    noPerformances: "Ainda não há performances",
    noArchivedPerformances: "Ainda não há performances arquivadas",
    actives: "Ativas",
    scheduled: "Agendadas",
    finalized: "Finalizadas",
    typeOfEmptyBody: {
      actives: "Ainda não há pesquisas ativas",
      scheduled: "Ainda não há pesquisas agendadas",
      finalized: "Ainda não há pesquisas finalizadas",
      all: "Ainda não há pesquisas",
      completed: "Ainda não há pesquisas completadas",
      pendings: "Ainda não há pesquisas pendentes",
    },
    surveyType: "Tipo de Pesquisa",
    participationPercentage: "% de Participação",
    session: "Sessões",
    scheduleDate: "Data Agendada",
    finalizationDate: "Data de Finalização",
    deleteSurveySuccess: "Pesquisa excluída com sucesso",
    deleteSurveyError:
      "Houve um erro ao tentar excluir a pesquisa, por favor, tente novamente",
    category: {
      title: "Categoria",
      competence: "Competência",
      objetive: "Objetivo",
    },
    dimension: {
      title: "Digite a dimensão das perguntas",
      placeholder: "Dimensão",
    },
    excel: {
      importObjetives: {
        title: "Importe aqui a atribuição de objetivos",
        button: " Carregar Objetivos",
        titleSuccess: "Objetivos carregados com sucesso",
      },
      importAssigments: {
        title: "Importe aqui a atribuição de colaboradores",
        button: " Carregar Atribuições",
        titleSuccess: "Atribuições carregadas com sucesso",
      },
    },
    addSessionModal: {
      title: "Atribuições de Desempenho",
      evaluatorSelector: "Avaliador",
      evaluatedSelector: "Avaliado",
      required: "Obrigatório *",
    },
    sessions: {
      deleteAssignment: "Excluir atribuição",
      deleteSession: "Excluir sessão",
      titleModal: "Tem certeza de que deseja excluir esta atribuição?",
      titleModalAllDelete:
        "Tem certeza de que deseja excluir todas as atribuições deste avaliador?",
      deleteSessions: {
        successMessage: "Sessão excluída com sucesso!",
        errorMessage:
          "Houve um erro ao tentar excluir a sessão, por favor, tente novamente",
      },
      createSessions: {
        successMessage: "Sessão criada com sucesso!",
        errorMessage:
          "Houve um erro ao tentar criar a sessão, por favor, tente novamente",
      },
    },
    sessionDetail: {
      state: {
        completed: "Concluído",
        inProgress: "Em progresso",
      },
    },
  },
  objectives: {
    editObjectives: {
      title: "Título",
      deadline: "Prazo final",
      grades: "Notas",
      scale: "Escala",
      weighting: "Ponderação",
    },
    editModal: {
      title: "Confirmar Alterações",
      description: "Tem certeza de que deseja salvar estas alterações?",
    },
    deleteModal: {
      title: "Confirmar Exclusão",
      description: "Tem certeza de que deseja excluir o objetivo?",
    },
    buttonModal: {
      save: "Salvar",
      delete: "Excluir",
    },
    alert: {
      successEdit: "As alterações foram executadas com sucesso",
      errorEdit: "As alterações não puderam ser executadas",
      successDelete: "Objetivo excluído",
      errorDelete: "A exclusão do objetivo falhou",
    },
    reports: {
      objectivesTitle: "Relatório de objectivos",
      objectivesDescription:
        "Baixe um arquivo .xlsx com todas as informações sobre seus objetivos",
      performanceTitle: "Relatório de desempenho",
      performanceDescription: "Em breve!",

      objectivesExport: {
        title: "Exportando Objectivos",
        exportButton: "Exportar",
        startDatePicker: "Desde:",
        endDatePicker: "Até:",
        endDatePlaceholder: "Selecione uma data de início",
        emailLabel: "Digite um e-mail:",
        emailPlaceholder: "exemplo@email.com",
        sendButton: "Enviar",
        alertSuccess:
          "Foi enviado com sucesso! Isso pode levar alguns minutos.",
        sending: "Enviando...",
        error: {
          NO_EMAIL: "É necessário um endereço de e-mail.",
          MISSING_PARAMETERS:
            "Faltam parâmetros necessários. Por favor, entre em contato com o suporte.",
          INVALID_DATE: "Data inválida.",
          NO_OBJECTIVES: "Nenhum objetivo encontrado para esta conta.",
          NO_OBJECTIVES_IN_DATERANGE:
            "Nenhum objetivo encontrado no intervalo de datas.",
          INTERNAL_ERROR:
            "Ocorreu um erro inesperado, tente novamente ou entre em contato com o suporte.",
        },
      },
    },
  },
};

export default surveys;
