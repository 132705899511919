const surveys = {
  surveys: {
    title: "Your surveys",
    newSurvey: "New survey",
    actives: "Actives",
    scheduled: "Scheduled",
    archived: "Archived",
    drafts: "Drafts",
    archiveTitle: "¿Do you want to archive this survey?",
    totalUsersSent: "Sent to",
    totalUsersResponse: "Responded",
    totalUserResponse: "Answered",
    coworker: "coworker",
    coworkers: "coworkers",
    all: "all",
    editFields: "Title and description",
    sendReminder: "Send reminder to collaborators who have not responded",
    anonymous: {
      true: "This survey is anonymous; the administrator will not be able to see your individual responses.",
      false:
        "This survey is not anonymous; the administrator will be able to see your individual responses.",
    },
    questions: {
      helper: {
        text: "Enter your response here",
        multipleChoice: "Select an option",
        priorityOrder: "Drag the options in the order of priority you prefer",
        nps: 'Where 0 is "Would not recommend at all" and 10 is "Would absolutely recommend"',
      },
      npsTextLow: "What aspects should we improve to increase your score?",
      npsTextHigh:
        "What can we do to ensure you continue recommending us as a workplace?",
    },
    answer: {
      alert:
        "¡This is a preview of the survey that will be sent to employees, subsequent responses are not saved.!",
    },
    team: {
      singular: "Team ",
      plural: "Teams ",
      seeAll: "See teams",
    },
    segment: {
      singular: "Segment ",
      plural: "Segments ",
      seeAll: "See segments",
    },
    individual: {
      singular: "Employee",
      plural: "Employees",
      seeAll: "See all employees",
    },

    warningMessage: {
      alertWarningMessage:
        "Must have at least four individuals in some category",
    },
    table: {
      title: "Title",
      status: "Status",
      percentageResponse: "% response",
      createdAt: "Creation date",
      scheduled: "scheduled for",
      visibility: "Visibility",
    },
    show: {
      surveys: "Surveys",
      detailSurvey: "Survey's detail",
      responseRate: "Response rate",
      status: "Status",
      active: "Active",
      archived: "Archived",
      published: "Published",
      scheduled: "Scheduled",
      content: "Content",
      question: "question",
      questions: "questions",
      anonymous: "Anonymous",
      noAnonymous: "No anonymous",
      visibility: "Visibility",
    },
    create: {
      buttonMore: {
        seeDraft: "See draft",
        seeTemplate: "See template",
        seeSurvey: "See survey",
        saveDraft: "Save as a draft",
        deleteDraft: "Delete draft",
        saveTemplate: "Save as a template",
        saveAsNewTemplate: "Save as a new Template",
        updateTemplate: "Update template",
        delete: "Delete survey",
        deleteTemplate: "Delete template",
      },
      firstStep: {
        dialogTitle: "Create custom survey",
        dialogTotleTemplate: "New template",
        title: "Title",
        titlePlaceholder: "Write survey name",
        titlePlaceholderTemplate: "Write template name",
        description: "Description",
        finalMessage: "Add message at the end of the survey",
        descriptionPlaceholder: "Write a description",
        required: "Required",
        lowUsers:
          "You need a minimum of 4 collaborators for an anonymous survey",
      },
      secondStep: {
        questionSummary: "Question {{number}}",
        questionTitle: "Question's title",
        questionTitlePlaceholder: "How do you describe your partners?",
        title: "Question type",
        text: "Text",
        nps: {
          title: "NPS",
          label:
            "The <strong>NPS</strong> questions are automatically set on a <strong>scale from 0 to 10</strong>, where 0 is <strong>Would not recommend at all</strong> and 10 is <strong>Would absolutely recommend</strong>. Users who respond with a 9 or 10 will be considered as <strong>promoters</strong>, 7-8 as <strong>passives</strong>, and 0-6 as <strong>detractors</strong>.<br />Additionally, both passives and detractors must provide comments to complete their evaluation.",
        },
        opinionScale: {
          title: "Opinion scale",
          minLabel: "Label 0",
          minLabelPlaceholder: "Example: strongly disagree",
          maxLabel: "Label 10",
          maxLabelPlaceholder: "Example: totally agree",
        },
        multipleChoice: {
          title: "Multiple choice",
          optionLabel: "Option {{number}}",
          optionLabelPlaceholder: "Option {{number}}",
        },
        priorityOrder: {
          title: "Priority order",
          optionLabel: "Option {{number}}",
          optionLabelPlaceholder: "Option {{number}}",
        },
        newQuestion: "Add another question",
        newOption: "Add another option",
      },

      thirdStep: {
        dialogTitle: "Audience and permissions",
        dialogSubtitle: "Inactive users will NOT receive this survey",
        title: "¿Who will carry out this custom survey?",
        creator: "Creator",
        organization: "Organization",
        audience: "Audience",
        all: "All",
        team: "Team",
        segment: "Segment",
        selectTeam: "Select team",
        selectSegment: "Select segment",
        shippingOptions: "Shipping options",
        sendNow: "Send now",
        scheduleShipping: "Schedule",
        selectDate: "Date",
        selectTime: "Time",
        previsualization: "Shipping previsualization",
        anonymous: "Anonymous survey",
        notAnonymous: "Non-anonymous survey",
        deliveryOptions: "Delivery options",
      },
      editFields: "New title and description",
      newTemplateFields: "Title and description of the new template",
      success: "¡Survey created successfully!",
      button: "See my surveys",
      saveSuccess: "¡Survey sent successfully!",
      scheduledSuccess: "¡Survey scheduled successfully!",
      saveSuccessTempalte: "¡Template saved successfully!",
      saveError: "There was an error creating the survey, please try again",
      saveErrorTemplate:
        "There was an error creating the template, please try again.",
      noQuestions: "Sending the survey should include at least one question",
    },
    user: {
      beginTitle: "Begin",
      back: "Back",
      next: "Next",
      end: "Finish",
      complete: "Completed",
      incomplete: "Incomplete",
      close: "Close this window",
      thanks: "Thanks for your answers!",
      deleteColabs: "¿Are you sure you want to delete this collaborator?",
      deleteColabsTitle: "Delete Collaborator",
      addColabsTitle: "Collaborator you want to add",
      addColabs: "Select the collaborator you want to add",
      textOption: {
        placeholder: "Leave your answer here",
      },
      labels: "Where 0 is {{ badLabel }} and 10 is {{ goodLabel }}",
    },
    tooltips: {
      visibility: "See results",
      sendReminder: "Send reminder",
      archive: "Archive survey",
      download: "Download report",
      cancelSending: "Cancel sending",
      reschedule: "Reschedule survey",
      information:
        "The team or segment requires 4 non-deactivated users (anonymous survey) or 1 (non-anonymous) to be included in the audience",
      addColabs: "Add collaborators",
    },
    report: {
      success: "Report successfully downloaded",
      noInfo: "There are no answers yet to download",
    },
    reminder: {
      successMessage: "Survey reminder was sent successfully",
      errorMessage:
        "There was an error sending the survey reminder, please try again",
    },
    addColabs: {
      title: "Choose collaborators to add to the survey",
      required: "Select at least 1",
      successMessage: "Collaborators added successfully",
      successDeleteMessage: "Collaborator deleted successfully",
      errorMessage:
        "There was an error adding the collaborators, please try again",
      errorDeleteMessage:
        "There was an error deleting the collaborator, please try again",
    },
    confirmation: {
      title: "This survey will be sent automatically",
      subtitle: "Do you want to continue?",
    },
    newSurveysButton: {
      emptyPage: "Blank survey",
      template: "Survey from template",
    },
    templateModal: {
      title: "¿Do you want to save the survey as a template?",
      subtitle:
        "This survey will be saved as a template in the templates section to be used in future surveys",
      buttonSave: "Save as template",
      notSave: "Don't save",
    },
    newTemplate: {
      saveTemplate: "Save tempate",
      deleteTemplate: "Delete template",
      useTemplate: "Use template",
      edit: "Edit",
      titleDeleteModal: "¿Are you sure you want to delete this template?",
    },
    seeAllAnswers: "See all answers",
    answers: {
      emptyStateTitle: "No answers yet",
      emptyStateSubtitle: "When there are any, they are displayed here",
      emptyStateScheduleTitle: "Shipping survey scheduled for",
    },
    responseRate: {
      first: "Response",
      second: "rate",
    },
    scheduledSurvey: {
      titleDeleteModal:
        "¿Would you rather delete or save the survey as a draft?",
      errorDeleteMessage:
        "There was an error deleting the scheduled survey, please try again",
      deleteSuccess: "Survey deleted successfully",
      saveAsDraft: "Save as a draft",
      deleteScheduledSurvey: "Delete",
      changeScheduleAtSuccess: "Survey was successfully scheduled",
    },
    template: {
      title: "Templates",
      newSurveysButton: "Create new template",
      used: "Used {{date}}",
      created: "Created {{date}}",
      updated: "Updated {{date}}",
      notUsed: "Not used yet",
      successMessage: "¡Template saved successfully!",
      noQuestions: "To save templated should include at least one question",
      noQuestionsUse: "To use templated should include at least one question",
      templateExist:
        "There is already a template. If you want to save the changes, please enter a new title for the template.",
      errorMessage:
        "There was an error creating the template, please try again",
      errorDeleteMessage:
        "There was an error deleting the template, please try again",
      emptyStateTitle: "No template yet",
      emptyStateSubtitle:
        "You can create it by clicking the button Create a new template",
      emptyStateButtonCreate: "Or by clicking here",
    },
    draftSurvey: {
      title: "Draft",
      draftSurveyExist:
        "¡Existing draft! Change the title if you want to save the draft",
      noQuestions: "To use this draft should include at least one question",
      errorMessage: "There was an error creating the draft, please try again",
      successDeleteMessage: "¡Draft created successfully!",
      errorDeleteMessage:
        "There was an error deleting the draft, please try again",
      successMessage: "¡Draft saved successfully!",
      useDraft: "Use draft",
      titleDeleteModal: "¿Are you sure you want to delete this draft?",
      saveAsDraft: "The survey was saved as a draft",
    },
    bozTemplate: {
      title: "Boz templates",
      seeAllTemplates: "See all Boz templates",
    },
    multipleChoice: {
      lessThan2Options:
        "The multiple-choice questions require at least 2 options",
    },
    opinionScale: {
      lessThan2Values:
        "The opinion scale questions require that all labels be complete",
    },
    priorityOrder: {
      lessThan4Options:
        "The priority order questions require a minimum of 4 options",
      buttonSeeMore: "Show more...",
    },
    imagesSurvey: {
      anonymousSurvey: "Anonymous survey",
      publicSurvey: "Public survey",
    },
    descriptionsPrefix: {
      anonymousSurvey: "⚠️ YOUR RESPONSES WILL BE ANONYMOUS ⚠️",
      publicSurvey: "⚠️ YOUR ANSWERS WILL BE PUBLIC ⚠️",
      description: "Description",
      subtitle: "Custom survey",
    },
    onboarding: {
      title:
        "Before starting the survey, we ask you to complete this information",
      teams: {
        noSelected: "I don't know yet",
      },
    },
  },
  daily_survey: {
    hello: "Hello",
    title: "Your daily survey has arrived!",
    begin: "Begin",
    previous: "Previous",
    next: "Next",
    end: "End",
    success_title: "Your daily survey was saved successfully",
    privacy: "Remember your answer will remain 100% anonymous",
    close: "Close this window",
    expired: "This daily survey already expired",
  },
  performance: {
    title: "Performance Evaluation",
    new: "New Performance",
    export: "Export",
    performanceSections: {
      title: "Performance BOZ",
      evaluationsTitle: "Evaluations: Competencies and Objectives",
      evaluationsDescription: "Create performance evaluations",
      objectivesTitle: "Objectives",
      objectivesDescription: "Define team objectives in {{name}}",
      valuesTitle: "Values / Principles",
      valuesDescription: "Define performance evaluation dimensions",
      scalesTitle: "Evaluation Scales",
      scalesDescription:
        "Create performance evaluation scales to evaluate your employees",
      reportsTitle: "Reports",
      reportsDescription:
        "Reporting for performance visualization and analytics",
    },
    performanceSurveys: {
      new: "New Survey",
      title: "Performance Surveys",
      seeAllTemplates: "See All Surveys",
      createSurvey: "Create Survey",
      surveyType: "Survey Type: {{ type }}",
      openSurvey: "Open Survey",
      deleteSurvey: "Delete Survey",
      titleCancelScheduledSurvey:
        "Are you sure you want to cancel the scheduled survey?",
      cancelScheduledSurvey: "Cancel",
      errorCancelMessage:
        "There was an error canceling the scheduled survey, please try again",
      cancelSuccess: "Survey canceled successfully",
    },
    autoevaluation: "Self-evaluation",
    managerTeam: "Manager to Team",
    teamManager: "Team to Manager",
    surveyTypeText: "Choose the survey type *",
    tabs: {
      all: "All",
      completed: "Completed",
      pendings: "Pending",
      updateAt: "Update Date",
    },
    name: "Name",
    evaluator: "Evaluator",
    status: "Status",
    percentageResponse: "% Response",
    totalSessions: "Total Sessions",
    noPerformances: "No performances yet",
    noArchivedPerformances: "No archived performances yet",
    actives: "Active",
    scheduled: "Scheduled",
    finalized: "Finalized",
    typeOfEmptyBody: {
      actives: "There are no active surveys yet",
      scheduled: "There are no scheduled surveys yet",
      finalized: "There are no finalized surveys yet",
      all: "There are no surveys yet",
      completed: "There are no completed surveys yet",
      pendings: "There are no pending surveys yet",
    },
    surveyType: "Survey Type",
    participationPercentage: "% Participation",
    session: "Sessions",
    scheduleDate: "Scheduled Date",
    finalizationDate: "Finalization Date",
    deleteSurveySuccess: "Survey deleted successfully",
    deleteSurveyError:
      "There was an error trying to delete the survey, please try again",
    category: {
      title: "Category",
      competence: "Competence",
      objetive: "Objective",
    },
    dimension: {
      title: "Enter the dimension of the questions",
      placeholder: "Dimension",
    },
    excel: {
      importObjetives: {
        title: "Import objective assignment here",
        button: "Upload Objectives",
        titleSuccess: "Objectives uploaded successfully",
      },
      importAssigments: {
        title: "Import collaborator assignment here",
        button: "Upload Assignments",
        titleSuccess: "Assignments uploaded successfully",
      },
    },
    addSessionModal: {
      title: "Performance Assignments",
      evaluatorSelector: "Evaluator",
      evaluatedSelector: "Evaluated",
      required: "Required *",
    },
    sessions: {
      deleteAssignment: "Delete assignment",
      deleteSession: "Delete session",
      titleModal: "Are you sure you want to delete this assignment?",
      titleModalAllDelete:
        "Are you sure you want to delete all assignments of this evaluator?",
      deleteSessions: {
        successMessage: "Session successfully deleted!",
        errorMessage:
          "There was an error trying to delete the session, please try again",
      },
      createSessions: {
        successMessage: "Session successfully created!",
        errorMessage:
          "There was an error trying to create the session, please try again",
      },
    },
    sessionDetail: {
      state: {
        completed: "Completed",
        inProgress: "In progress",
      },
    },
  },
  objectives: {
    editObjectives: {
      title: "Title",
      deadline: "Deadline",
      grades: "Grades",
      scale: "Scale",
      weighting: "Weighting",
    },
    editModal: {
      title: "Confirm Changes",
      description: "Are you sure you want to save these changes?",
    },
    deleteModal: {
      title: "Confirm Deletion",
      description: "Are you sure you want to delete the objective?",
    },
    buttonModal: {
      save: "Save",
      delete: "Delete",
    },
    alert: {
      successEdit: "Changes were executed successfully",
      errorEdit: "Changes could not be executed",
      successDelete: "Objective deleted",
      errorDelete: "Objective deletion failed",
    },
    reports: {
      objectivesTitle: "Objectives Report",
      objectivesDescription:
        "Download an .xlsx file with all the information about your objectives",
      performanceTitle: "Performance report",
      performanceDescription: "Coming soon!",

      objectivesExport: {
        title: "Objectives Export",
        exportButton: "Export ",
        startDatePicker: "From:",
        endDatePicker: "To:",
        endDatePlaceholder: "Select a start date",
        emailLabel: "Enter an email:",
        emailPlaceholder: "example@email.com",
        sendButton: "Send",
        alertSuccess:
          "It has been sent successfully! This may take a few minutes.",
        sending: "Sending...",
        error: {
          NO_EMAIL: "Email address is required.",
          MISSING_PARAMETERS:
            "Missing required parameters. Please contact support.",
          INVALID_DATE: "Invalid date.",
          NO_OBJECTIVES: "No objectives found for this account.",
          NO_OBJECTIVES_IN_DATERAGE: "No objectives found within date range.",
          INTERNAL_ERROR:
            "Unexpected error occured, please try again or contact support.",
        },
      },
    },
  },
};

export default surveys;
