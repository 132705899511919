const ai = {
  ai: {
    dialog: {
      title: {
        create: {
          customSurvey: "Crear encuesta personalizada con asistente de IA",
        },
      },
      description: {
        create: {
          customSurvey:
            "Describa la encuesta que desea crear, o cargue un archivo con sus preguntas (.txt o .xlsx), y nuestro asistente de IA le ayudará a diseñarla.",
        },
      },
      placeholder: "Escriba su prompt aquí...",
      fileUpload: {
        title: "Cargar archivo",
        clickToUpload: "Haga clic para cargar un archivo",
        orDragAndDrop: "o arrastre y suelte un archivo aquí",
        supportedFormats: "Archivos permitidos: .txt, .xlsx",
        invalidFileType: "Por favor, cargue solo archivos .txt o .xlsx",
        fileTooLarge:
          "El archivo es demasiado grande. El tamaño máximo es de 10MB.",
      },
    },
  },
};

export default ai;
