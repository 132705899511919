import { combineReducers } from "redux";
import authReducer from "./authReducers";
import errorReducer from "./errorReducers";
import feedbackReducer from "./feedbackReducers";
import surveyReducer from "./surveyReducers";
import accountReducer from "./accountReducers";
import infoReducer from "./infoReducer";
import loaderReducer from "./loaderReducer";
import videoReducers from "./videoReducers";
import recognitionImageReducers from "./recognitionImageReducers ";
import aiWorkflowReducers from "./aiWorkflowReducers";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  feedback: feedbackReducer,
  surveys: surveyReducer,
  account: accountReducer,
  info: infoReducer,
  loader: loaderReducer,
  video: videoReducers,
  recognitionImage: recognitionImageReducers,
  aiAgents: aiWorkflowReducers,
});
