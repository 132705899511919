const TimeOff = {
  timeOff: {
    title: "Tempo Livre",
    user: "Usuário",
    team: "Equipa",
    accumulated: "Acumulado",
    requested: "Solicitado",
    pending: "Pendente",
    all: "Todos",
    days: "Dias",
    name: "Nome",
    category: "Categoria",
    status: "Estado",
    approved: "Aprovado",
    rejected: "Rejeitado",
    history: "Histórico de solicitudes",
    freeDays: "Dias livres",
    noFreeDays: "Nenhuma politica de tempo livre",
    noHistory: "Nenhum histórico de solicitudes",
    detailTitle: "Detalhe de uso de dias livres",
    headersDays: {
      category: "Categoria",
      politic: "Política",
      solicited: "Solicitado",
      accumulated: "Acumulado",
    },
    headersHistory: {
      date: "Data",
      politic: "Política",
      solicited: "Solicitado",
      approved: "Aprovado",
      state: "Estado",
    },
    filter: {
      team: "Filtrar por equipa",
      policy: "Filtrar por política",
    },
    tabs: {
      all: "Todos",
      admin: "Administrativo",
      legal: "Legal",
      additional: "Adicional",
    },
    months: {
      0: "Janeiro",
      1: "Fevereiro",
      2: "Março",
      3: "Abril",
      4: "Maio",
      5: "Junho",
      6: "Julho",
      7: "Agosto",
      8: "Setembro",
      9: "Outubro",
      10: "Novembro",
      11: "Dezembro",
    },
  },
};

export default TimeOff;
